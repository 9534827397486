<template>
  <div
    id="sidenav-collapse-main"
    class="w-auto h-auto collapse navbar-collapse max-height-vh-100 h-100"
  >
    <ul class="navbar-nav">
      <li class="nav-item Disponibilidad" v-if="tienePermiso('acceso_disponibilidad')">
        <sidenav-collapse
          nav-text="Disponibilidad"
          :collapse="true"
          url="#"
          :aria-controls="''"
          collapse-ref="#"
        >
          <template #icon>
            <img
              src="http://issyrental-store-prod.s3.us-east-1.amazonaws.com/Administracion/iconos/calendar_month_white_24dp.svg">
          </template>
          <template #list>
            <ul class="nav pe-0  ms-3">
              <sidenav-item v-if="tienePermiso('acceso_disponibles')"
                            :to="{ name: 'Disponibles' }"
                            mini-icon="http://issyrental-store-prod.s3.us-east-1.amazonaws.com/Administracion/iconos/disponible.png"
                            text="Check Disponibilidad"
                            :activo="getRoute() === 'Disponibles'"
                            @click="cerrar_menu"
              />
              <sidenav-item v-if="tienePermiso('acceso_contratados')"
                            :to="{ name: 'Contratados' }"
                            :activo="getRoute() === 'Contratados'"
                            mini-icon="http://issyrental-store-prod.s3.us-east-1.amazonaws.com/Administracion/iconos/contrato.png"
                            text="Contratados"
                            @click="cerrar_menu"
              />
              <sidenav-item v-if="tienePermiso('acceso_reservados')"
                            :to="{ name: 'Reservados' }"
                            mini-icon="http://issyrental-store-prod.s3.us-east-1.amazonaws.com/Administracion/iconos/reservado.png"
                            text="Reservados"
                            :activo="getRoute() === 'Reservados'"
                            @click="cerrar_menu"
              />
            </ul>
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item Reservas" v-if="tienePermiso('acceso_reservas')">
        <sidenav-collapse
          nav-text="Reservas"
          :collapse="true"
          url="#"
          :aria-controls="''"
          collapse-ref="#"
        >
          <template #icon>
            <img
              src="http://issyrental-store-prod.s3.us-east-1.amazonaws.com/Administracion/iconos/edit_calendar_white_24dp.svg">
          </template>
          <template #list>
            <ul class="nav pe-0 ms-3">
              <sidenav-item v-if="tienePermiso('acceso_reservas_administracion')"
                            :to="{ name: 'Reservas desde la Administración' }"
                            mini-icon="http://issyrental-store-prod.s3.us-east-1.amazonaws.com/Administracion/iconos/settings_white_24dp.svg"
                            text="Reservar"
                            :activo="getRoute() === 'Reservas desde la Administración'"
                            @click="cerrar_menu"
              />
              <sidenav-item v-if="tienePermiso('acceso_reservas_portal')"
                            :to="{ name: 'Reservas desde el Portal Web' }"
                            mini-icon="http://issyrental-store-prod.s3.us-east-1.amazonaws.com/Administracion/iconos/public_white_24dp.svg"
                            text="Portal web"
                            :activo="getRoute() === 'Reservas desde el Portal Web'"
                            @click="cerrar_menu"
              />
              <sidenav-item v-if="tienePermiso('acceso_reservas_noshow')"
                            :to="{ name: 'Reservas No Show' }"
                            mini-icon="http://issyrental-store-prod.s3.us-east-1.amazonaws.com/Administracion/iconos/no_show.svg"
                            text="No Show"
                            :activo="getRoute() === 'Reservas No Show'"
                            @click="cerrar_menu"
              />
              <sidenav-item v-if="tienePermiso('acceso_reservas_canceladas')"
                            :to="{ name: 'Reservas canceladas' }"
                            mini-icon="http://issyrental-store-prod.s3.us-east-1.amazonaws.com/Administracion/iconos/cancel.svg"
                            text="Canceladas"
                            :activo="getRoute() === 'Reservas canceladas'"
                            @click="cerrar_menu"
              />
            </ul>
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item Contratos" v-if="tienePermiso('acceso_contratos')">
        <sidenav-collapse
          nav-text="Contratos"
          :collapse="true"
          url="#"
          :aria-controls="''"
          collapse-ref="#"
        >
          <template #icon>
            <img src="http://issyrental-store-prod.s3.us-east-1.amazonaws.com/Administracion/iconos/contrato.svg">
          </template>
          <template #list>
            <ul class="nav pe-0 ms-3">
              <sidenav-item v-if="tienePermiso('acceso_contratos')"
                            :to="{ name: 'Contratos abiertos' }"
                            mini-icon="http://issyrental-store-prod.s3.us-east-1.amazonaws.com/Administracion/iconos/contratos.svg"
                            text="Abiertos"
                            :activo="getRoute() === 'Contratos abiertos'"
                            @click="cerrar_menu"
              />
              <sidenav-item v-if="tienePermiso('acceso_contratos')"
                            :to="{ name: 'Contratos vencidos' }"
                            mini-icon="http://issyrental-store-prod.s3.us-east-1.amazonaws.com/Administracion/iconos/contratos.svg"
                            text="Vencidos"
                            :activo="getRoute() === 'Contratos vencidos'"
                            @click="cerrar_menu"
              />
              <sidenav-item v-if="tienePermiso('acceso_contratos')"
                            :to="{ name: 'Contratos cerrados' }"
                            mini-icon="http://issyrental-store-prod.s3.us-east-1.amazonaws.com/Administracion/iconos/contratos.svg"
                            text="Cerrados"
                            :activo="getRoute() === 'Contratos cerrados'"
                            @click="cerrar_menu"
              />
              <sidenav-item v-if="tienePermiso('acceso_contratos')"
                            :to="{ name: 'Contratos cancelados' }"
                            mini-icon="http://issyrental-store-prod.s3.us-east-1.amazonaws.com/Administracion/iconos/contratos.svg"
                            text="Cancelados"
                            :activo="getRoute() === 'Contratos cancelados'"
                            @click="cerrar_menu"
              />
            </ul>
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item Entrega_Autos" v-if="tienePermiso('acceso_entrega_autos')"
      >
        <sidenav-collapse
          nav-text="Entrega de autos"
          :collapse="false"
          url="#"
          :class="getRoute() === 'Entrega de autos' ? 'active' : ''"
          :aria-controls="''"
          collapse-ref="#"
          @click="irEntregaAutos"
        >
          <template #icon>
            <img src="@/assets/img/icons/entraga_car.svg">
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item Cotizaciones" v-if="tienePermiso('acceso_cotizaciones')"
      >
        <sidenav-collapse
          nav-text="Cotizaciones"
          :collapse="false"
          url="#"
          :aria-controls="''"
          collapse-ref="#"
          :class="getRoute() === 'Cotizaciones' ? 'active' : ''"
          @click="irCotizaciones"
        >
          <template #icon>
            <img src="http://issyrental-store-prod.s3.us-east-1.amazonaws.com/Administracion/iconos/cotizacion.svg">
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item CuentasCobrar" v-if="tienePermiso('acceso_cuentas_por_cobrar')"
      >
        <sidenav-collapse
          nav-text="Cuentas por cobrar"
          :collapse="false"
          url="#"
          :class="getRoute() === 'Cuentas por cobrar' ? 'active' : ''"
          :aria-controls="''"
          collapse-ref="#"
          @click="irCuentas"
        >
          <template #icon>
            <img src="@/assets/img/icons/cuentas.svg">
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item Vehiculos" v-if="tienePermiso('acceso_vehiculos')">
        <sidenav-collapse
          collapse-ref="vehiculos"
          nav-text="Vehículos"
        >
          <template #icon>
            <img
              src="http://issyrental-store-prod.s3.us-east-1.amazonaws.com/Administracion/iconos/directions_car_white_24dp.svg">
          </template>
          <template #list>
            <ul class="nav pe-0  ms-3">
              <sidenav-item v-if="tienePermiso('gestionar_categorias')"
                            :to="{ name: 'Vehículos' }"
                            mini-icon="http://issyrental-store-prod.s3.us-east-1.amazonaws.com/Administracion/iconos/directions_car_white_24dp.svg"
                            text="Listado"
                            :activo="getRoute() === 'Vehículos'"
                            @click="cerrar_menu"
              />
              <sidenav-item v-if="tienePermiso('gestionar_categorias')"
                            :to="{ name: 'Categorías' }"
                            mini-icon="http://issyrental-store-prod.s3.us-east-1.amazonaws.com/Administracion/iconos/background_grid_small.svg"
                            text="Categoría"
                            :activo="getRoute() === 'Categorías'"
                            @click="cerrar_menu"
              />
              <sidenav-item v-if="tienePermiso('gestionar_marcas')"
                            :to="{ name: 'Marcas' }"
                            mini-icon="http://issyrental-store-prod.s3.us-east-1.amazonaws.com/Administracion/iconos/check_circle.svg"
                            text="Marca"
                            :activo="getRoute() === 'Marcas'"
                            @click="cerrar_menu"
              />
              <sidenav-item v-if="tienePermiso('gestionar_modelos')"
                            :to="{ name: 'Modelos' }"
                            mini-icon="http://issyrental-store-prod.s3.us-east-1.amazonaws.com/Administracion/iconos/commute.svg"
                            text="Modelo"
                            :activo="getRoute() === 'Modelos'"
                            @click="cerrar_menu"
              />
              <sidenav-item v-if="tienePermiso('gestionar_versiones')"
                            :to="{ name: 'Versiones' }"
                            mini-icon="http://issyrental-store-prod.s3.us-east-1.amazonaws.com/Administracion/iconos/account_tree.svg"
                            text="Versión"
                            :activo="getRoute() === 'Versiones'"
                            @click="cerrar_menu"
              />
            </ul>
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item Clientes" v-if="tienePermiso('acceso_clientes')">
        <sidenav-collapse
          collapse-ref="clientes"
          nav-text="Clientes">
          <template #icon>
            <img
              src="http://issyrental-store-prod.s3.us-east-1.amazonaws.com/Administracion/iconos/groups_white_24dp.svg">
          </template>
          <template #list>
            <ul class="nav pe-0  ms-3">
              <sidenav-item v-if="tienePermiso('acceso_clientes')"
                            :to="{ name: 'Clientes' }"
                            mini-icon="http://issyrental-store-prod.s3.us-east-1.amazonaws.com/Administracion/iconos/groups_white_24dp.svg"
                            text="Listado"
                            :activo="getRoute() === 'Clientes'"
                            @click="cerrar_menu"
              />
              <sidenav-item v-if="tienePermiso('gestionar_tipos_clientes')"
                            :to="{ name: 'Tipos de Clientes' }"
                            mini-icon="http://issyrental-store-prod.s3.us-east-1.amazonaws.com/Administracion/iconos/settings_account_box.svg"
                            text="Tipo de Cliente"
                            :activo="getRoute() === 'Tipos de Clientes'"
                            @click="cerrar_menu"
              />
            </ul>
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item Notificaciones" v-if="tienePermiso('acceso_notificaciones')"
      >
        <sidenav-collapse
          nav-text="Notificaciones"
          :collapse="false"
          url="#"
          :aria-controls="''"
          :class="getRoute() === 'Notificaciones' ? 'active' : ''"
          collapse-ref="#"
          @click="goToNotifications"
        >
          <template #icon>
            <img height="24px" width="24px"
                 src="http://issyrental-store-prod.s3.us-east-1.amazonaws.com/Administracion/iconos/encrypted.svg">
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item Mantenimiento" v-if="tienePermiso('acceso_mantenimiento')">
        <sidenav-collapse
          nav-text="Mantenimiento"
          :collapse="true"
          url="#"
          :aria-controls="''"
          collapse-ref="#"
        >
          <template #icon>
            <img src="http://issyrental-store-prod.s3.us-east-1.amazonaws.com/Administracion/iconos/mantenimiento.svg">
          </template>
          <template #list>
            <ul class="nav pe-0 ms-3">
              <sidenav-item v-if="tienePermiso('acceso_mantenimiento')"
                            :to="{ name: 'Listados de vehículos para mantenimiento' }"
                            mini-icon="http://issyrental-store-prod.s3.us-east-1.amazonaws.com/Administracion/iconos/directions_car_white_24dp.svg"
                            text="Listado"
                            :activo="getRoute() === 'Listados de vehículos para mantenimiento'"
                            @click="cerrar_menu"
              />
              <sidenav-item v-if="tienePermiso('acceso_mantenimiento')"
                            :to="{ name: 'Mantenimientos activos' }"
                            mini-icon="http://issyrental-store-prod.s3.us-east-1.amazonaws.com/Administracion/iconos/en_mantenimiento.svg"
                            text="Activos"
                            :activo="getRoute() === 'Mantenimientos activos'"
                            @click="cerrar_menu"
              />
              <sidenav-item v-if="tienePermiso('ver_historial_mantenimientos')"
                            :to="{ name: 'Historial de mantenimientos' }"
                            mini-icon="http://issyrental-store-prod.s3.us-east-1.amazonaws.com/Administracion/iconos/historial.svg"
                            text="Historial"
                            :activo="getRoute() === 'Historial de mantenimientos'"
                            @click="cerrar_menu"
              />
            </ul>
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item Administracion" v-if="tienePermiso('acceso_administracion')">
        <sidenav-collapse
          collapse-ref="administracion"
          nav-text="Administración">
          <template #icon>
            <img
              src="http://issyrental-store-prod.s3.us-east-1.amazonaws.com/Administracion/iconos/settings_white_24dp.svg">
          </template>
          <template #list>
            <ul class="nav pe-0  ms-3">
              <sidenav-item v-if="tienePermiso('gestionar_aseguradora')"
                            :to="{ name: 'Aseguradoras' }"
                            mini-icon="http://issyrental-store-prod.s3.us-east-1.amazonaws.com/Administracion/iconos/health_and_safety.svg"
                            text="Aseguradora"
                            :activo="getRoute() === 'Aseguradoras'"
                            @click="cerrar_menu"
              />
              <sidenav-item v-if="tienePermiso('gestionar_ciudades')"
                            :to="{ name: 'Ciudades' }"
                            mini-icon="http://issyrental-store-prod.s3.us-east-1.amazonaws.com/Administracion/iconos/location_city.svg"
                            text="Ciudad"
                            :activo="getRoute() === 'Ciudades'"
                            @click="cerrar_menu"
              />
              <sidenav-item v-if="tienePermiso('gestionar_estados')"
                            :to="{ name: 'Estados' }"
                            mini-icon="http://issyrental-store-prod.s3.us-east-1.amazonaws.com/Administracion/iconos/emoji_transportation.svg"
                            text="Estado"
                            :activo="getRoute() === 'Estados'"
                            @click="cerrar_menu"
              />
              <sidenav-item v-if="tienePermiso('gestionar_servicios_extras')"
                            :to="{ name: 'Servicios Extras' }"
                            mini-icon="http://issyrental-store-prod.s3.us-east-1.amazonaws.com/Administracion/iconos/Servicios.svg"
                            text="Servicios extras"
                            :activo="getRoute() === 'Servicios Extras'"
                            @click="cerrar_menu"
              />
              <sidenav-item v-if="tienePermiso('gestionar_sucursales')"
                            :to="{ name: 'Sucursales' }"
                            mini-icon="http://issyrental-store-prod.s3.us-east-1.amazonaws.com/Administracion/iconos/home_pin.svg"
                            text="Sucursal"
                            :activo="getRoute() === 'Sucursales'"
                            @click="cerrar_menu"
              />
              <sidenav-item v-if="tienePermiso('gestionar_temporadas')"
                            :to="{ name: 'Temporadas' }"
                            mini-icon="http://issyrental-store-prod.s3.us-east-1.amazonaws.com/Administracion/iconos/temporadas.svg"
                            text="Temporada alta"
                            :activo="getRoute() === 'Temporadas'"
                            @click="cerrar_menu"
              />
              <sidenav-item v-if="tienePermiso('gestionar_titulares')"
                            :to="{ name: 'Titulares' }"
                            mini-icon="http://issyrental-store-prod.s3.us-east-1.amazonaws.com/Administracion/iconos/badge.svg"
                            text="Titular"
                            :activo="getRoute() === 'Titulares'"
                            @click="cerrar_menu"
              />
              <sidenav-item v-if="tienePermiso('gestionar_coberturas')"
                            :to="{ name: 'Coberturas' }"
                            mini-icon="http://issyrental-store-prod.s3.us-east-1.amazonaws.com/Administracion/iconos/cobertura.png"
                            text="Coberturas"
                            :activo="getRoute() === 'Coberturas'"
                            @click="cerrar_menu"
              />
              <sidenav-item v-if="tienePermiso('gestionar_precio_gasolina')"
                            :to="{ name: 'Precio de la Gasolina' }"
                            mini-icon="http://issyrental-store-prod.s3.us-east-1.amazonaws.com/Administracion/iconos/gasolina.svg"
                            text="Precio de la Gasolina"
                            :activo="getRoute() === 'Precio de la Gasolina'"
                            @click="cerrar_menu"
              />
            </ul>
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item Cortesías" v-if="tienePermiso('acceso_cortesias')">
        <sidenav-collapse
          nav-text="Cortesías"
          :collapse="true"
          url="#"
          :aria-controls="''"
          collapse-ref="#"
        >
          <template #icon>
            <img
              src="@/assets/img/icons/cortesia.svg">
          </template>
          <template #list>
            <ul class="nav pe-0  ms-3">
              <sidenav-item :to="{ name: 'Cortesías' }"
                            mini-icon="http://issyrental-store-prod.s3.us-east-1.amazonaws.com/Administracion/iconos/cortesia.svg"
                            text="Listado"
                            :class="getRoute() === 'Cotesías' ? 'active' : ''"
                            @click="cerrar_menu"
              />
              <sidenav-item :to="{ name: 'Cortesías entregadas' }"
                            mini-icon="http://issyrental-store-prod.s3.us-east-1.amazonaws.com/Administracion/iconos/cortesia_list.svg"
                            text="Entregadas"
                            :class="getRoute() === 'Cortesías entregadas' ? 'active' : ''"
                            @click="cerrar_menu"
              />
            </ul>
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item Portal" v-if="tienePermiso('acceso_portal')">
        <sidenav-collapse
          collapse-ref="portal"
          nav-text="Portal Web">
          <template #icon>
            <img
              src="http://issyrental-store-prod.s3.us-east-1.amazonaws.com/Administracion/iconos/public_white_24dp.svg">
          </template>
          <template #list>
            <ul class="nav pe-0  ms-3">
              <sidenav-item v-if="tienePermiso('gestionar_faq')"
                            :to="{ name: 'FAQ' }"
                            mini-icon="http://issyrental-store-prod.s3.us-east-1.amazonaws.com/Administracion/iconos/help.svg"
                            text="FAQ"
                            :activo="getRoute() === 'FAQ'"
                            @click="cerrar_menu"
              />
              <sidenav-item v-if="tienePermiso('ver_comentarios_clientes')"
                            :to="{ name: 'Comentarios' }"
                            mini-icon="http://issyrental-store-prod.s3.us-east-1.amazonaws.com/Administracion/iconos/comentarios.svg"
                            text="Comentarios"
                            :activo="getRoute() === 'Comentarios'"
                            @click="cerrar_menu"
              />
              <sidenav-item v-if="tienePermiso('gestionar_sliders')"
                            :to="{ name: 'Sliders' }"
                            mini-icon="http://issyrental-store-prod.s3.us-east-1.amazonaws.com/Administracion/iconos/slider.svg"
                            text="Sliders"
                            :activo="getRoute() === 'Sliders'"
                            @click="cerrar_menu"
              />
              <sidenav-item v-if="tienePermiso('gestionar_sliders')"
                            :to="{ name: 'Información' }"
                            mini-icon="http://issyrental-store-prod.s3.us-east-1.amazonaws.com/Administracion/iconos/info.svg"
                            text="Información"
                            :activo="getRoute() === 'Información'"
                            @click="cerrar_menu"
              />
            </ul>
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item Seguridad" v-if="tienePermiso('acceso_seguridad')">
        <sidenav-collapse
          collapse-ref="seguridad"
          nav-text="Seguridad"
        >
          <template #icon>
            <img
              src="http://issyrental-store-prod.s3.us-east-1.amazonaws.com/Administracion/iconos/lock_open_white_24dp.svg">
          </template>
          <template #list>
            <ul class="nav pe-0 ms-3">
              <sidenav-item v-if="tienePermiso('acceso_usuarios')"
                            :to="{ name: 'Usuarios' }"
                            mini-icon="http://issyrental-store-prod.s3.us-east-1.amazonaws.com/Administracion/iconos/account_circle.svg"
                            text="Usuarios"
                            :activo="getRoute() === 'Usuarios'"
                            @click="cerrar_menu"
              />
              <sidenav-item v-if="tienePermiso('acceso_roles')"
                            :to="{ name: 'Roles' }"
                            mini-icon="http://issyrental-store-prod.s3.us-east-1.amazonaws.com/Administracion/iconos/assignment_ind.svg"
                            text="Roles"
                            :activo="getRoute() === 'Roles'"
                            @click="cerrar_menu"
              />
              <sidenav-item v-if="tienePermiso('acceso_permisos')"
                            :to="{ name: 'Permisos' }"
                            mini-icon="http://issyrental-store-prod.s3.us-east-1.amazonaws.com/Administracion/iconos/encrypted.svg"
                            text="Permisos"
                            :activo="getRoute() === 'Permisos'"
                            @click="cerrar_menu"
              />
              <sidenav-item v-if="tienePermiso('acceso_permisos_por_roles')"
                            :to="{ name: 'Gestion de Permisos de Roles' }"
                            mini-icon="http://issyrental-store-prod.s3.us-east-1.amazonaws.com/Administracion/iconos/admin_panel_settings.svg"
                            text="Permisos de Roles"
                            :activo="getRoute() === 'Gestion de Permisos de Roles'"
                            @click="cerrar_menu"
              />
            </ul>
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item Reportes" v-if="tienePermiso('acceso_reportes')"
      >
        <sidenav-collapse
          nav-text="Reportes"
          :collapse="false"
          url="#"
          :class="getRoute() === 'Reportes' ? 'active' : ''"
          :aria-controls="''"
          collapse-ref="#"
          @click="irReportes"
        >
          <template #icon>
            <img src="@/assets/img/icons/reportes.svg">
          </template>
        </sidenav-collapse>
      </li>
    </ul>
  </div>
</template>
<script>
import SidenavItem from "./SidenavItem.vue";
import SidenavCollapse from "./SidenavCollapse.vue";
import SidenavCollapseItem from "./SidenavCollapseItem.vue";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";

export default {
  name: "SidenavList",
  components: {
    SidenavItem,
    SidenavCollapse,
    SidenavCollapseItem
  },
  data() {
    return {
      title: "Soft UI Dashboard PRO",
      controls: "dashboardsExamples",
      isActive: "active",
      username: this.$store.state.username
    };
  },
  computed: {
    ...mapGetters(["tienePermiso"])
  },
  methods: {
    ...mapMutations(["navbarMinimize", "toggleConfigurator"]),
    cerrar_menu() {
      if (window.innerWidth < 550) {
        this.navbarMinimize();
      }
    },
    irContratos() {
      this.$router.push("/contratos/listado");
      this.cerrar_menu();
    },
    irCotizaciones() {
      this.$router.push("/cotizaciones");
      this.cerrar_menu();
    },
    goToNotifications() {
      this.$router.push("/notifications");
      this.cerrar_menu();
    },
    goToCortesias() {
      this.$router.push("/cortesias");
      this.cerrar_menu();
    },
    irReportes() {
      this.$router.push("/reportes");
      this.cerrar_menu();
    },
    irCuentas() {
      this.$router.push("/cuentas");
      this.cerrar_menu();
    },
    irEntregaAutos() {
      this.$router.push("/entrega_autos");
      this.cerrar_menu();
    },
    getRoute() {
      return this.$route.name
    },
    logout() {
      this.$store.state.isAuth = false;
      localStorage.clear();
      this.$router.push("/");
    }
  }
};

</script>
 <style scoped>

 </style>